import React from 'react';
import { Regex } from 'utils/strings';

/* eslint-disable no-param-reassign */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
export class HelperFunctions {
  static mergeObj(source: any, value: any) {
    for (const propName in value) {
      if (value[propName] !== '') {
        if (typeof value[propName] === 'object' && source.hasOwnProperty(propName)) {
          source[propName] = this.mergeObj(source[propName], value[propName]);
        } else if (value[propName] === 'true') {
          source[propName] = true;
        } else if (value[propName] === 'false') {
          source[propName] = false;
        } else {
          source[propName] = value[propName];
        }
      }
    }
    return source;
  }
}
export const formatHtml = (html: string) => {
  let updatedHtml = html;
  updatedHtml = updatedHtml.replace(/className/g, 'class');
  return updatedHtml;
};

export const copyToClipboard = async (text: string) => {
  await navigator.clipboard.writeText(text);
};

export const getValidURL = (url: string) => (!Regex.ValidURLRegex.test(url) ? `//${url}` : url);

export const AllowNumberForMobile = (
  e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
) => {
  e.currentTarget.value = e.currentTarget.value.replace(/[^\d]/g, '');
  return false;
};
