export const ROUTES = {
  LOGIN: '/',
  DASHBOARD: '/dashboard',
  PROFESSIONAL: '/professional',
  PROFESSIONAL_DETAILS: '/professional/:id',
  CHANGE_PASSWORD: '/change-password',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password/:id',
  CLIENT: '/client',
  CLIENT_DETAILS: '/client/:id',
  CHANGE_ORDER_REQUESTS: '/change-order-requests',
  CHANGE_ORDER_REQUESTS_DETAILS: '/change-order-requests/:id',
  PRODUCTS: '/products',

  // designer
  DesignerForgotPassword: '/designer/forgot-password',
  DesignerResetPassword: '/designer/reset-password',
  DesignerChangePassword: '/designer/change-password',
  DesignerProfessional: '/designer/professional',
  DesignerProfessionalDetails: '/designer/professional/details',
  DesignerClient: '/designer/client',
  DesignerClientDetails: '/designer/client/details',
  DesignerChangeOrder: '/designer/change-order',
  DesignerChangeOrderDetails: '/designer/change-order/details',
  DesignerProduct: '/designer/product',
};
