import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './assets/css/reset.css';
import './assets/css/fonts.css';
import './assets/css/spacer.css';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import environment from 'environment';
import App from './App';
import reportWebVitals from './reportWebVitals';

Sentry.init({
  dsn: environment.sentryDSN,
  integrations: [new Sentry.Replay()],
  // uncomment next line on development to disable the sentry integration
  // enabled: false,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  environment: environment.currentEnv,
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
