import { createTheme } from '@mui/material/styles';
import images from '../assets/images';

// eslint-disable-next-line import/no-mutable-exports
let VexxitTheme = createTheme({
  palette: {
    primary: {
      main: '#094849',
      light: '#056468',
    },
    secondary: {
      main: '#BEBEBE',
      dark: '#094849',
    },
    text: {
      primary: '#272727',
      secondary: '#FFFFFF',
      disabled: '#A3A3A3',
    },
    error: {
      main: '#B71C1C',
    },
    warning: {
      main: '#FF6F00',
    },
    success: {
      main: '#1B5E20',
    },
  },
});

VexxitTheme = createTheme(VexxitTheme, {
  typography: {
    fontFamily: '"MaderaRegular", sans-serif',
    h1: {
      fontSize: '3rem',
      lineHeight: 1.2,
      fontWeight: 300,
      letterSpacing: 0,
      [VexxitTheme.breakpoints.up('sm')]: {
        fontSize: '4rem',
        letterSpacing: '-0.5px',
      },
      [VexxitTheme.breakpoints.up('md')]: {
        fontSize: '5rem',
        letterSpacing: '-1px',
      },
      [VexxitTheme.breakpoints.up('lg')]: {
        fontSize: '6rem',
        lineHeight: 1.2083333,
        letterSpacing: '-1.5px',
      },
    },
    h2: {
      fontSize: '2rem',
      lineHeight: 1.2,
      fontWeight: 300,
      [VexxitTheme.breakpoints.up('sm')]: {
        fontSize: '2.5rem',
        letterSpacing: 0,
      },
      [VexxitTheme.breakpoints.up('md')]: {
        fontSize: '3.75rem',
        letterSpacing: '-0.5px',
        lineHeight: 1.2083333,
      },
    },
    h3: {
      fontSize: '2rem',
      lineHeight: 1.25,
      fontWeight: 400,
      letterSpacing: 0,
      [VexxitTheme.breakpoints.up('sm')]: { fontSize: '2.5rem' },
      [VexxitTheme.breakpoints.up('md')]: {
        fontSize: '3rem',
        lineHeight: 1.2083333,
      },
    },
    h4: {
      fontSize: '1.5rem',
      lineHeight: 1.25,
      fontWeight: 400,
      letterSpacing: 0,
      [VexxitTheme.breakpoints.up('md')]: {
        fontSize: '2.125rem',
        letterSpacing: '0.25px',
        lineHeight: 1.235294,
      },
    },
    h5: {
      fontSize: '1.25rem',
      lineHeight: 1.25,
      fontWeight: 400,
      letterSpacing: 0,
      [VexxitTheme.breakpoints.up('sm')]: { fontSize: '1.5rem' },
    },
    h6: {
      fontSize: '1.2rem',
      lineHeight: 1.25,
      fontWeight: 500,
      letterSpacing: 0,
      [VexxitTheme.breakpoints.up('sm')]: {
        fontSize: '1.25rem',
        lineHeight: 1.3,
        letterSpacing: '0.15px',
      },
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.375,
      fontWeight: 400,
      letterSpacing: '0.5px',
    },
    body2: {
      fontSize: '0.875rem',
      lineHeight: 1.42857,
      fontWeight: 400,
      letterSpacing: '0.25px',
    },
    subtitle1: {
      fontSize: '1rem',
      lineHeight: 1.375,
      fontWeight: 400,
      letterSpacing: '0.15px',
    },
    subtitle2: {
      fontSize: '0.875rem',
      lineHeight: 1.42857,
      fontWeight: 500,
      letterSpacing: '0.1px',
    },
    caption: {
      fontSize: '0.75rem',
      lineHeight: 1.3333333,
      fontWeight: 400,
      letterSpacing: '0.4px',
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: 16,
          paddingRight: 16,
          [VexxitTheme.breakpoints.up('sm')]: {
            paddingLeft: 12,
            paddingRight: 12,
          },
        },
      },
    },

    // Appbar
    MuiAppBar: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
        colorDefault: {
          backgroundColor: [VexxitTheme.palette.text.secondary],
        },
      },
    },

    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: 40,
          [VexxitTheme.breakpoints.up('sm')]: {
            minHeight: 38,
          },
          '&.MuiToolbar-root': {
            paddingLeft: 16,
            paddingRight: 16,
            [VexxitTheme.breakpoints.up('sm')]: {
              paddingLeft: 12,
              paddingRight: 12,
            },
          },
        },
      },
    },

    MuiTypography: {
      defaultProps: {
        fontFamily: '"MaderaRegular", sans-serif',
      },
    },

    // Buttons
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 400,
          paddingLeft: 22,
          paddingRight: 22,
          borderRadius: 28,
          letterSpacing: 0,
          boxShadow: 'none!important',
          minWidth: 'auto',
          '&.Mui-disabled': {
            background: '#747474',
            color: [VexxitTheme.palette.text.secondary],
            boxShadow: 'none!important',
            cursor: 'not-allowed',
          },
        },
        containedSizeSmall: {
          fontFamily: '"MaderaMedium", sans-serif',
          fontWeight: 500,
          boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.23), 0px 3px 6px rgba(0, 0, 0, 0.16)!important',
          fontSize: 14,
          lineHeight: '20px',
          padding: '5px 16px 11px',
          '& .MuiButton-startIcon': {
            marginTop: 4,
          },
          '& .MuiButton-endIcon': {
            marginTop: 4,
          },
        },
        containedSizeMedium: {
          fontFamily: '"MaderaMedium", sans-serif',
          fontWeight: 500,
          fontSize: 16,
          lineHeight: '22px',
          padding: '8.5px 23px 14.5px 23px',
          '& .MuiButton-endIcon': {
            marginBottom: -5,
          },
          '& .MuiButton-startIcon': {
            marginBottom: -5,
          },
        },
        containedSizeLarge: {
          fontFamily: '"MaderaMedium", sans-serif',
          fontWeight: 500,
          boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.23), 0px 3px 6px rgba(0, 0, 0, 0.16)!important',
          fontSize: 16,
          lineHeight: '22px',
          letterSpacing: '0.5px',
          padding: '11px 28px 15px',
          [VexxitTheme.breakpoints.up('md')]: {
            padding: '13px 32px 17px',
          },
        },
        containedPrimary: {
          '&.MuiButton-root.btn-primary-light': {
            color: [VexxitTheme.palette.text.secondary],
            background: [VexxitTheme.palette.primary.light],
            '&:hover, &:focus': {
              background: [VexxitTheme.palette.primary.main],
              color: [VexxitTheme.palette.text.secondary],
            },
          },
          '&.MuiButton-root.btn-shadow': {
            boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.35) !important',
          },
          '&.MuiButton-root.btn-white': {
            color: [VexxitTheme.palette.primary.main],
            background: [VexxitTheme.palette.text.secondary],
            '&:hover, &:focus': {
              background: [VexxitTheme.palette.primary.main],
              color: [VexxitTheme.palette.text.secondary],
            },
          },
        },
        containedSecondary: {
          color: [VexxitTheme.palette.secondary.dark],
          '&:hover, &:focus': {
            background: [VexxitTheme.palette.primary.light],
            color: [VexxitTheme.palette.text.secondary],
          },
        },
        outlinedSizeSmall: {
          fontFamily: '"MaderaMedium", sans-serif',
          fontWeight: 500,
          fontSize: 14,
          lineHeight: '18px',
          padding: '7px 15px 12px',
          '& .MuiButton-endIcon': {
            marginBottom: -5,
            marginRight: -4,
          },
          '& .MuiButton-startIcon': {
            marginTop: -2,
            marginLeft: -4,
          },
        },
        outlinedSizeMedium: {
          fontFamily: '"MaderaMedium", sans-serif',
          fontWeight: 500,
          fontSize: 16,
          lineHeight: '22px',
          padding: '7px 23px 12px 23px',
          '& .MuiButton-endIcon': {
            marginBottom: -5,
          },
          '& .MuiButton-startIcon': {
            marginTop: -2,
          },
        },
        outlinedSizeLarge: {
          fontFamily: '"MaderaMedium", sans-serif',
          fontWeight: 500,
          fontSize: 16,
          lineHeight: '22px',
          padding: '8px 28px 14px',
          [VexxitTheme.breakpoints.up('md')]: {
            padding: '11px 32px 15px',
          },
        },
        outlinedPrimary: {
          color: [VexxitTheme.palette.primary.main],
          border: `2px solid${[VexxitTheme.palette.primary.main]}!important`,
          '&:hover, &:focus': {
            background: [VexxitTheme.palette.primary.main],
            color: [VexxitTheme.palette.text.secondary],
          },
          '&.btn-white-outlined': {
            color: [VexxitTheme.palette.text.secondary],
            border: `2px solid${[VexxitTheme.palette.text.secondary]}!important`,
            '&:hover, &:focus': {
              background: [VexxitTheme.palette.text.secondary],
              color: [VexxitTheme.palette.primary.main],
            },
          },
          '&.Mui-disabled': {
            opacity: 0.6,
            color: [VexxitTheme.palette.primary.main],
            cursor: 'not-allowed',
          },
        },
        outlinedSecondary: {
          color: '#4E4E4E',
          border: '1px solid #BEBEBE !important',
          '&:hover, &:focus': {
            background: 'rgba(78,78,78,0.11)',
          },
          '&.Mui-disabled': {
            opacity: 0.6,
            color: [VexxitTheme.palette.primary.main],
            cursor: 'not-allowed',
          },
        },
        textSizeSmall: {
          fontFamily: '"MaderaMedium", sans-serif',
          fontWeight: 500,
          fontSize: 14,
          lineHeight: '22px',
          padding: '6.5px 20px 9.5px',
          '& .MuiButton-iconSizeSmall': {
            marginTop: 4,
          },
        },
        textSizeMedium: {
          fontFamily: '"MaderaMedium", sans-serif',
          fontWeight: 500,
          fontSize: 16,
          lineHeight: '22px',
          padding: '9px 23px 14px 23px',
        },
        textSizeLarge: {
          fontFamily: '"MaderaMedium", sans-serif',
          fontWeight: 500,
          fontSize: 16,
          lineHeight: '22px',
          padding: '10px 28px 16px',
          [VexxitTheme.breakpoints.up('md')]: {
            padding: '12px 32px 18px',
          },
        },
        textPrimary: {
          color: '#094849',
          background: '#E1F1F4',
          '&:hover, &:focus': {
            background: 'rgba(9,72,73,0.16)',
          },
        },

        startIcon: {
          marginRight: 6,
          marginLeft: -4,
          '& .MuiAvatar-root': {
            width: 20,
            height: 20,
          },
        },
        endIcon: {
          marginLeft: 6,
          marginRight: -4,
          '& .MuiAvatar-root': {
            width: 20,
            height: 20,
          },
        },
      },
    },

    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&.MuiButtonBase-root[aria-expanded="true"]': {
            background: 'rgba(9,72,73,0.11)',
          },
        },
      },
    },

    // Icon Buttons
    MuiIconButton: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
        },
        sizeSmall: {
          padding: 5,
        },
        sizeMedium: {
          padding: 8,
        },
        colorPrimary: {
          '&.btn-white': {
            background: '#E1F1F4',
            '&:hover': {
              background: '#EFEFEF',
            },
          },
          '&:hover': {
            background: 'rgb(9,72,73,0.1)',
          },
          '&.btn-light': {
            background: '#F2F7F8',
            '&:hover': {
              background: 'rgba(10,73,74,0.15)',
            },
            '&.Mui-disabled': {
              opacity: 0.72,
              cursor: 'no-drop',
            },
          },
        },
      },
    },

    // MuiAvatar
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 24,
          height: 24,
        },
      },
    },

    // MenuItem
    MuiMenuItem: {
      styleOverrides: {
        root: {
          [VexxitTheme.breakpoints.up('sm')]: {
            minHeight: 42,
          },
          [VexxitTheme.breakpoints.up('md')]: {
            minHeight: 48,
          },
          '& .MuiListItemText-root': {
            paddingBottom: 4,
            fontFamily: '"MaderaMedium", sans-serif',
            fontSize: 16,
            lineHeight: 22,
            color: '#4E4E4E',
          },
        },
      },
    },

    // Rating
    MuiRating: {
      styleOverrides: {
        sizeSmall: {
          '& .MuiRating-icon': {
            marginRight: 1,
          },
          '& .MuiSvgIcon-root': {
            width: 20,
            height: 20,
          },
        },
        root: {
          marginLeft: -2,
          '& .MuiRating-icon': {
            width: 24,
            height: 24,
            marginRight: -2,
            '& .MuiSvgIcon-root': {
              width: 24,
              height: 24,
            },
          },
        },
        icon: {
          transition: 'all 0.1s ease',
          '& .MuiSvgIcon-root': {
            '& path': {
              display: 'none',
            },
          },
        },
        iconEmpty: {
          '& .MuiSvgIcon-root': {
            backgroundImage: `url(${images.Rating})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '24px 24px',
            backgroundPosition: 'center center',
          },
        },
        iconFilled: {
          '& .MuiSvgIcon-root': {
            backgroundImage: `url(${images.RatingFilled})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '24px 24px',
            backgroundPosition: 'center center',
          },
        },
      },
    },

    // Accordion
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&.primary-accordion': {
            border: '1px solid #949494',
            borderRadius: '8px!important',
            transition: 'all 0.2s ease',
            [VexxitTheme.breakpoints.up('lg')]: {
              borderRadius: '15px!important',
            },
            '& .MuiAccordionSummary-root': {
              padding: '0 16px',
              '& .MuiAccordionSummary-content': {
                fontFamily: "'BitterMediumItalic', serif",
                fontStyle: 'italic',
                color: [VexxitTheme.palette.primary.main],
                margin: '17px 0',
                fontSize: 16,
                lineHeight: '20px',
                transition: 'all 0.1s ease',
                '&.Mui-expanded': {
                  fontWeight: 600,
                },
                [VexxitTheme.breakpoints.up('lg')]: {
                  margin: '22px 0 24px',
                  fontSize: 18,
                  lineHeight: '24px',
                },
              },
              '& .MuiAccordionSummary-expandIconWrapper': {
                minWidth: 24,
                height: 24,
                marginLeft: 10,
                borderRadius: '50%',
                background: [VexxitTheme.palette.primary.light],
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              },
              [VexxitTheme.breakpoints.up('lg')]: {
                padding: '0 20px 0 25px',
              },
              '&.Mui-expanded': {
                margin: 0,
                minHeight: 'auto',
              },
            },
            '& .MuiCollapse-root': {
              '& .MuiAccordionDetails-root': {
                padding: '0 16px 20px',
                [VexxitTheme.breakpoints.up('lg')]: {
                  padding: '0 25px 20px',
                },
              },
            },
            '&+.primary-accordion': {
              marginTop: 18,
              [VexxitTheme.breakpoints.up('lg')]: {
                marginTop: 25,
              },
            },
            '&.Mui-expanded': {
              marginBottom: 0,
              '&:hover': {
                border: '1px solid #949494',
                backgroundColor: '#FFFFFF',
              },
            },
            '&:before': {
              content: 'none',
            },
            '&:hover': {
              border: `1px solid${[VexxitTheme.palette.primary.main]}`,
              backgroundColor: 'rgba(10, 73, 74, 0.07)',
            },
          },
        },
      },
    },

    // MuiFormControlLabel
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          '&.switch-wrapper': {
            marginRight: -9,
          },
          '&.Mui-disabled': {
            cursor: 'no-drop',
          },
        },
      },
    },

    // MuiSwitch
    MuiSwitch: {
      styleOverrides: {
        root: {
          '& + .MuiFormControlLabel-label': {
            fontWeight: 400,
            fontFamily: 'MaderaRegular, sans-serif',
            fontSize: '0.875rem',
            lineHeight: 1.2,
            letterSpacing: '0.25px',
            color: '#4E4E4E',
            paddingBottom: 2,
            transition: '150ms cubic-bezier(0.4, 0, 0.2, 1)',
          },
        },
        switchBase: {
          '& +.MuiChecked .MuiSwitch-track': {
            backgroundColor: '#B6CBCB',
          },
        },
        track: {
          backgroundColor: '#747474',
          borderRadius: 16,
          opacity: 1,
          boxShadow:
            '0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)',
        },
      },
    },

    // MuiBreadcrumbs
    MuiBreadcrumbs: {
      styleOverrides: {
        li: {
          fontSize: 14,
          fontFamily: '"BitterItalic", serif',
          lineHeight: '30px',
          '& .active': {
            color: '#595959',
          },
        },
        separator: {
          color: [VexxitTheme.palette.primary.main],
          fontSize: 14,
          fontFamily: '"BitterItalic", serif',
          lineHeight: '30px',
        },
      },
    },

    // MuiDrawer
    MuiDrawer: {
      styleOverrides: {
        root: {
          '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(211,211,211,0.6)',
          },
        },
      },
    },

    // MuiDialog
    MuiDialog: {
      styleOverrides: {
        root: {
          '&.primary-dialog': {
            '& .MuiBackdrop-root': {
              backgroundColor: 'rgba(211,211,211,0.6)',
            },
            '& .MuiDialog-paper': {
              borderRadius: 10,
              margin: 16,
              maxWidth: 'calc(100% - 32px)',
              backgroundColor: [VexxitTheme.palette.text.secondary],
              boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.22), 0px 14px 28px rgba(0, 0, 0, 0.25)',
              [VexxitTheme.breakpoints.up('md')]: {
                borderRadius: 16,
                margin: 32,
                maxWidth: 'calc(100% - 64px)',
              },
              '& .MuiDialogTitle-root': {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '9px 16px 13px',
                borderBottom: '1px solid #E1E1E1',
                [VexxitTheme.breakpoints.up('md')]: {
                  padding: '13px 22px 17px',
                },
                '& .btn-close-dialog': {
                  marginBottom: -3,
                  marginRight: -8,
                },
                '& .heading': {
                  color: [VexxitTheme.palette.primary.light],
                  fontSize: '1.125rem',
                  [VexxitTheme.breakpoints.up('md')]: {
                    fontSize: '1.5rem',
                  },
                },
              },
              '& .MuiDialogContent-root': {
                padding: '12px 18px 20px',
                [VexxitTheme.breakpoints.up('md')]: {
                  padding: '12px 24px 20px',
                },
              },
              '& .MuiDialogActions-root': {
                padding: '24px 24px',
                justifyContent: 'flex-end',
                [VexxitTheme.breakpoints.up('md')]: {
                  padding: '24px 30px',
                  justifyContent: 'center',
                },
              },
            },
          },
          '&.admin-dialog': {
            '& .MuiBackdrop-root': {
              backgroundColor: 'rgba(211,211,211,0.6)',
            },
            '& .MuiDialog-paper': {
              borderRadius: 10,
              backgroundColor: [VexxitTheme.palette.text.secondary],
              boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.22), 0px 14px 28px rgba(0, 0, 0, 0.25)',
              [VexxitTheme.breakpoints.up('md')]: {
                borderRadius: 16,
              },
              '& .MuiDialogTitle-root': {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '11px 16px 13px',
                borderBottom: '1px solid #E1E1E1',
                [VexxitTheme.breakpoints.up('md')]: {
                  padding: '14px 22px 18px',
                },
                '& .btn-close-dialog': {
                  marginTop: -3,
                  marginBottom: -5,
                  marginRight: -4,
                  [VexxitTheme.breakpoints.up('md')]: {
                    marginBottom: -7,
                    marginRight: -8,
                    '& .MuiAvatar-root': {
                      width: 24,
                      height: 24,
                    },
                  },
                },
                '& .heading': {
                  color: '#056468',
                  fontSize: '1.125rem',
                  [VexxitTheme.breakpoints.up('md')]: {
                    fontSize: '1.5rem',
                  },
                },
              },
              '& .MuiDialogContent-root': {
                padding: '12px 18px 20px',
                [VexxitTheme.breakpoints.up('md')]: {
                  padding: '12px 24px 20px',
                },
              },
              '& .MuiDialogActions-root': {
                padding: '24px 24px',
                justifyContent: 'flex-end',
                [VexxitTheme.breakpoints.up('md')]: {
                  padding: '24px 30px',
                  justifyContent: 'center',
                },
              },
            },
          },
        },
        paperWidthSm: {
          maxWidth: 600,
          width: 600,
        },
        paperWidthMd: {
          maxWidth: 810,
          width: 810,
        },
      },
    },

    // MuiFormLabel
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: [VexxitTheme.palette.primary.main],
          fontFamily: '"Bitter", serif!important',
          fontStyle: 'italic',
          fontWeight: 400,
          letterSpacing: '-0.2px',
          top: -3,
          left: 10,
        },
        shrink: {
          top: 1,
        },
        outlined: {
          left: 8,
          padding: '0 6px',
          fontSize: 16,
          lineHeight: '22px',
          fontFamily: '"Bitter", serif!important',
          fontStyle: 'italic',
          fontWeight: 400,
          letterSpacing: '0.5px',
          color: '#4E4E4E',
          maxWidth: 'calc(100% - 55px)',
        },
        sizeSmall: {
          top: 0,
          fontSize: '14px!important',
          lineHeight: '18px',
          letterSpacing: '0.25px',
          padding: '0 4px!important',
        },
      },
    },

    // MuiOutlinedInput
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: '#BEBEBE',
          transition: 'all 0.1s ease',
          '&:hover': {
            borderColor: [VexxitTheme.palette.primary.main],
          },
          '& legend': {
            marginLeft: 12,
          },
        },
        root: {
          borderRadius: 30,
          marginBottom: 16,
          '& .MuiInputAdornment-positionEnd': {
            marginRight: 5,
          },
          '&.placeholder-input': {
            paddingLeft: 4,
            '& .MuiOutlinedInput-input': {
              paddingLeft: 8,
              paddingRight: 8,
              '&::-webkit-input-placeholder': {
                color: '#4E4E4E',
                opacity: 1,
              },
              '&::-ms-input-placeholder': {
                color: '#4E4E4E',
                opacity: 1,
              },
              '&::placeholder': {
                color: '#4E4E4E',
                opacity: 1,
              },
            },
          },
          '&.Mui-disabled': {
            cursor: 'no-drop',
          },
        },
        input: {
          padding: '12.5px 26px 14.5px',
          fontFamily: '"Bitter", serif',
          fontStyle: 'italic',
          fontWeight: 400,
          color: '#4E4E4E',
          fontSize: 16,
          lineHeight: '22px',
          letterSpacing: '0.5px',
          textOverflow: 'ellipsis',
          justifyContent: 'flex-start',
          '&.MuiInputBase-inputSizeSmall': {
            padding: '9px 20px 11px',
            fontSize: 14,
            lineHeight: '18px',
            letterSpacing: '0.25px',
          },
          '&.Mui-disabled': {
            cursor: 'no-drop',
          },
        },
      },
    },

    // MuiSelect
    MuiSelect: {
      styleOverrides: {
        select: {
          display: 'block',
          padding: '10.5px 44px 16.5px 26px',
          paddingRight: 40,
          fontFamily: '"Bitter", serif',
          fontWeight: 400,
          fontStyle: 'italic',
          color: '#272727',
          fontSize: 16,
          lineHeight: '22px',
          letterSpacing: '0.5px',
          '& .MuiListItemIcon-root': {
            minWidth: 'auto',
            marginRight: 10,
          },
          '& .MuiListItemText-root': {
            fontSize: 16,
            lineHeight: 20,
            paddingBottom: 2,
          },
          '&.MuiInputBase-inputSizeSmall': {
            padding: '10px 34px 10px 20px!important',
            paddingRight: 34,
            justifyContent: 'flex-start',
          },
        },
        iconOutlined: {
          right: 10,
          '& path': {
            display: 'none',
          },
          transition: 'all 0.1s ease',
          backgroundImage: `url(${images.ChevronDownGrey})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundSize: '20px 20px',
        },
      },
    },

    // MuiFormHelperText
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: '#4b4b4b',
          fontWeight: 400,
          marginLeft: 0,
          marginTop: 8,
          position: 'absolute',
          top: 'auto',
          bottom: -8,
          left: 24,
          fontFamily: '"Bitter", serif',
          fontStyle: 'italic',
          fontSize: 14,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: 'calc(100% - 48px)',
          letterSpacing: '0.25px',
        },
      },
    },

    // MuiRadio
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: 8,
          '& .MuiSvgIcon-root[data-testid="RadioButtonUncheckedIcon"]': {
            fill: '#C1C1C1',
            transition: 'all 0.1s ease',
          },
          '& .MuiSvgIcon-root[data-testid="RadioButtonCheckedIcon"]': {
            '& path': {
              display: 'none',
            },
            backgroundSize: '24px 24px',
            backgroundRepeat: 'no-repeat',
            transition: 'all 0.1s ease',
          },
          '& +.MuiFormControlLabel-label': {
            fontFamily: '"MaderaRegular", sans-serif',
            fontWeight: 400,
            color: '#4E4E4E',
            fontSize: 16,
            lineHeight: '22px',
            paddingBottom: 4,
          },
        },
      },
    },

    // MuiCheckbox
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 8,
          '& .MuiSvgIcon-root': {
            fill: '#747474',
            transition: 'all 0.1s ease',
          },
          '& +.MuiFormControlLabel-label': {
            fontFamily: '"MaderaRegular", sans-serif',
            fontWeight: 400,
            color: '#272727',
            fontSize: 14,
            lineHeight: '20px',
            paddingBottom: 4,
            letterSpacing: '0.25px',
          },
        },
        colorPrimary: {
          '& .MuiSvgIcon-root': {
            fill: '#747474',
            transition: 'all 0.1s ease',
          },
          '&.Mui-checked .MuiSvgIcon-root': {
            fill: [VexxitTheme.palette.primary.main],
          },
          '&.Mui-disabled .MuiSvgIcon-root': {
            opacity: 0.72,
            cursor: 'no-drop',
          },
        },
      },
    },

    // MuiFormGroup
    MuiFormGroup: {
      styleOverrides: {
        row: {
          '& .MuiFormControlLabel-root': {
            marginLeft: -10,
            marginRight: 24,
            [VexxitTheme.breakpoints.up('md')]: {
              marginRight: 42,
            },
            '&.Mui-disabled': {
              cursor: 'no-drop',
            },
          },
        },
      },
    },

    // MuiFormControl
    MuiFormControl: {
      styleOverrides: {
        root: {
          '&.select-control': {
            '& .MuiInputLabel-root': {
              maxWidth: 'calc(100% - 56px)',
            },
          },
        },
      },
    },

    // MuiToggleButton
    MuiToggleButton: {
      styleOverrides: {
        root: {
          '&.custom-radio-button': {
            backgroundColor: [VexxitTheme.palette.text.secondary],
            border: '1px solid #949494!important',
            borderRadius: '15px!important',
            margin: '12px!important',
            padding: '11px 15px',
            minWidth: 200,
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            transition: 'all 0.2s cubic-bezier(0, 0, 0.2, 1)',
            color: [VexxitTheme.palette.primary.main],
            '& .MuiAvatar-root': {
              marginRight: 8,
              width: 20,
              height: 20,
            },
            '& .radio-button-text': {
              fontSize: '1rem',
              lineHeight: '20px',
              letterSpacing: '-0.2px',
              fontFamily: '"MaderaMedium", sans-serif',
              fontWeight: 500,
              textTransform: 'none',
              marginBottom: 4,
            },
            '&:hover,&:focus': {
              backgroundColor: '#f4f4f4',
            },
            '&.Mui-selected': {
              backgroundColor: [VexxitTheme.palette.secondary.main],
              borderColor: '#008080!important',
              boxShadow: '0 2px 8px 1px rgba(0,0,0,0.25)',
            },
          },
        },
        sizeSmall: {
          '&.custom-radio-button': {
            padding: '6px 10px 8px',
            borderRadius: '10px!important',
            minWidth: 150,
            margin: '9px!important',
            '& .MuiAvatar-root': {
              marginRight: 8,
              width: 16,
              height: 16,
            },
            '& .radio-button-text': {
              fontSize: '0.75rem',
              lineHeight: '18px',
              fontFamily: '"MaderaMedium", sans-serif',
              fontWeight: 500,
              textTransform: 'none',
              marginBottom: 3,
            },
          },
        },
      },
    },

    // MuiChip
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
        outlinedPrimary: {
          border: '1px solid #004648',
          '& .MuiChip-label': {
            color: `1px solid${[VexxitTheme.palette.primary.light]}`,
          },
          '&.MuiChip-clickable:hover': {
            backgroundColor: 'rgba(24,128,128,0.12)',
          },
        },
        sizeSmall: {
          padding: '2px 12px 9px',
          minHeight: 34,
          height: 34,
          borderRadius: 8,
          '& .MuiChip-label': {
            padding: '0',
            fontWeight: 500,
            fontFamily: '"MaderaMedium", sans-serif',
            fontSize: '0.875rem',
            lineHeight: '20px',
            marginBottom: '2px',
            textAlign: 'center',
            letterSpacing: '0.25px',
          },
        },
        deleteIcon: {
          marginRight: 14,
        },
        filled: {
          fontSize: 14,
          lineHeight: '20px',
          paddingBottom: '4px',
        },
        filledError: {
          background: '#FFEBEE',
          color: [VexxitTheme.palette.error.main],
        },
        filledWarning: {
          background: '#FFF8E1',
          color: [VexxitTheme.palette.warning.main],
        },
        filledInfo: {
          background: '#EBFAFF',
          color: '#00ABE7',
        },
        filledSuccess: {
          background: '#E8F5E9',
          color: [VexxitTheme.palette.success.main],
        },
        filledDefault: {
          background: '#DBDBDB',
          color: '#272727',
          '&.filledPinkChips': {
            background: '#FFECFD',
            color: '#A846A0',
          },
          '&.filledRedChips': {
            background: '#FBE9E9',
            color: '#DB3131',
          },
          '&.filledBrownChips': {
            background: '#F8EEE4',
            color: '#7A542E',
          },
          '&.filledLightGreyChips': {
            background: '#F6F6F6',
            color: '#272727',
          },
          '&.filledInactiveChips': {
            background: '#F6F6F6',
            color: '#7A542E',
          },
          '&.filledSkyBlueChips': {
            background: '#EBFAFF',
            color: '#00ABE7',
          },
          '&.filledPrimaryLightChips': {
            background: '#E1F1F4',
            color: '#007479',
          },
        },
      },
    },

    // MuiBadge
    MuiBadge: {
      styleOverrides: {
        root: {
          '&.notification-badge': {
            '& .MuiBadge-badge': {
              fontFamily: '"MaderaMedium",sans-serif',
              fontWeight: 500,
              fontSize: 13,
              padding: '0 0 3px 0',
              minWidth: 16,
              lineHeight: 14,
              height: 16,
              borderRadius: 5,
              top: 9,
              right: 7,
              backgroundColor: '#d32f2f',
              color: '#fff',
            },
          },
        },
      },
    },

    // MuiTooltip
    MuiTooltip: {
      styleOverrides: {
        popper: {
          zIndex: `${1599}!important`,
        },
        arrow: {
          width: 16,
          height: 10,
          color: [VexxitTheme.palette.text.secondary],
        },
        tooltip: {
          backgroundColor: [VexxitTheme.palette.text.secondary],
          boxShadow: '0px 8px 16px -2px rgba(27, 33, 44, 0.12)',
          padding: '9px 12px 12px',
          borderRadius: 10,
          color: '#000',
        },
      },
    },

    // MuiSlider
    MuiSlider: {
      styleOverrides: {
        colorSecondary: {
          '& .MuiSlider-rail': {
            backgroundColor: '#EFF0F0',
            opacity: 1,
          },
        },
      },
    },

    // MuiFilledInput
    MuiFilledInput: {
      styleOverrides: {
        root: {
          fontFamily: '"MaderaMedium", sans-serif',
          fontSize: '1rem',
          border: '1px solid #949494',
          background: 'none!important',
          padding: '25px 20px 12px',
          borderRadius: 12,
          '&:before, &:after': {
            display: 'none',
          },
          '&:hover, &.Mui-focused': {
            border: `1px solid${[VexxitTheme.palette.primary.main]}`,
          },
        },
        input: {
          color: [VexxitTheme.palette.text.primary],
          fontFamily: '"MaderaRegular", sans-serif',
          fontSize: '1rem',
        },
      },
    },

    // MuiTextField
    MuiTextField: {
      styleOverrides: {
        root: {
          '&.filled-text-control': {
            '& .MuiInputLabel-root': {
              left: '8px!important',
              top: '2px!important',
              fontFamily: '"BitterMediumItalic", serif',
              fontSize: '1rem',
              color: '#595959',
            },
          },
        },
      },
    },

    // MuiTableContainer
    MuiTableContainer: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderRadius: 0,
          '&.normal-grid': {
            '& .MuiTable-root': {
              '& .MuiTableHead-root': {
                '& .MuiTableCell-root': {
                  padding: '12px 16px 16px',
                  fontFamily: "'MaderaMedium', sans-serif",
                  fontWeight: 500,
                  fontSize: 14,
                  lineHeight: '20px',
                  letterSpacing: '0.25px',
                  color: '#272727',
                  borderColor: '#E1E1E1',
                  whiteSpace: 'nowrap',
                  '& .MuiTableSortLabel-root.Mui-active': {
                    '& .MuiTableSortLabel-icon': {
                      marginBottom: -2,
                      color: '#747474',
                      width: 24,
                      height: 24,
                      opacity: 1,
                    },
                  },
                  '& .MuiTableSortLabel-root': {
                    '& .MuiTableSortLabel-icon': {
                      width: 24,
                      height: 24,
                      fill: '#747474',
                    },
                    '&:hover,&:focus': {
                      color: '#4e4e4e',
                    },
                  },
                },
              },
              '& .MuiTableBody-root': {
                '& .MuiTableRow-root.MuiTableRow-hover:hover': {
                  backgroundColor: 'rgba(9,72,73,0.05)',
                  cursor: 'pointer',
                },
                '& .MuiTableCell-root': {
                  padding: '12px 16px',
                  fontFamily: "'MaderaRegular', sans-serif",
                  fontWeight: 400,
                  fontSize: 14,
                  lineHeight: '20px',
                  letterSpacing: '0.25px',
                  color: '#272727',
                  borderColor: '#E1E1E1',
                  '& .grid-profile-block': {
                    display: 'flex',
                    alignItems: 'center',
                    '& .MuiAvatar-root': {
                      marginRight: 10,
                    },
                  },
                },
              },
            },
          },
        },
      },
    },

    // MuiTablePagination
    MuiTablePagination: {
      styleOverrides: {
        toolbar: {
          padding: '12px 12px 17px 12px',
          '& .MuiInputBase-root': {
            margin: '0 32px 0 10px',
            '& .MuiInputBase-input': {
              backgroundColor: 'none!important',
              borderRadius: '50px!important',
            },
            [VexxitTheme.breakpoints.down('md')]: {
              margin: '0 24px 0 12px',
            },
          },
          [VexxitTheme.breakpoints.down('sm')]: {
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            justifyContent: 'center',
            padding: 12,
            '& .MuiBox-root': {
              margin: '10px auto 0',
              width: '100%',
              textAlign: 'center',
            },
          },
        },
        selectLabel: {
          fontFamily: "'Bitter', serif",
          fontStyle: 'italic',
          fontWeight: 400,
          fontSize: '0.875rem',
          lineHeight: '20px',
          letterSpacing: '0.25px',
          color: '#272727',
          paddingBottom: 2,
        },
        selectIcon: {
          top: 8,
          right: 8,
          '& path': {
            display: 'none',
          },
          transition: 'all 0.1s ease',
          backgroundImage: `url(${images.ChevronDownGrey})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundSize: '20px 20px',
        },
        select: {
          padding: '8.5px 41px 8.5px 19px!important',
          border: '1px solid #BEBEBE',
          borderRadius: 50,
          fontFamily: "'Bitter', serif",
          fontStyle: 'italic',
          fontWeight: 400,
          fontSize: '0.875rem',
          lineHeight: '20px',
          letterSpacing: '0.25px',
          color: '#4E4E4E',
        },
        displayedRows: {
          fontFamily: "'MaderaRegular', sans-serif",
          fontWeight: 400,
          fontSize: '0.875rem',
          lineHeight: '20px',
          letterSpacing: '0.25px',
          color: '#272727',
          paddingBottom: 6,
        },
      },
    },

    // MuiMenu
    MuiMenu: {
      styleOverrides: {
        paper: {
          boxShadow: '0px 6px 6px rgba(0, 0, 0, 0.23), 0px 10px 20px rgba(0, 0, 0, 0.19)!important',
          borderRadius: '8px!important',
          '& .MuiMenuItem-root': {
            minHeight: 45,
            '& .MuiCheckbox-root': {
              margin: '-8px 0',
            },
            '&.no-hover': {
              backgroundColor: 'transparent!important',
            },
          },
          '&.MultiSelect': {
            maxHeight: 350,
            '& .MuiMenu-list': {
              '& .MuiMenuItem-root': {
                minHeight: 42,
                [VexxitTheme.breakpoints.up('sm')]: {
                  minHeight: 48,
                },
                '& .MuiCheckbox-root.MuiCheckbox-colorPrimary': {
                  margin: '0 0 0 -10px',
                  '& .MuiSvgIcon-root': {
                    fill: '#C1C1C1',
                  },
                  '&.Mui-checked': {
                    '& .MuiSvgIcon-root': {
                      fill: [VexxitTheme.palette.primary.main],
                    },
                  },
                },
              },
            },
          },
        },
      },
    },

    // MuiPopover
    MuiPopover: {
      styleOverrides: {
        root: {
          zIndex: 1599,
          boxShadow: '0 2px 8px 1px rgba(0,0,0,0.25)!important',
          '&.simple-tooltip .MuiPaper-root': {
            backgroundColor: '#FFFFFF',
            padding: 14,
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.22)',
            borderRadius: 16,
            color: '#4E4E4E',
            fontSize: 12,
            lineHeight: 16,
            letterSpacing: '0.4px',
          },
          [VexxitTheme.breakpoints.up('lg')]: {
            '&.rating-count-dropdown': {
              right: 'auto!important',
              bottom: 'auto!important',
            },
          },
        },
      },
    },
  },
});

export default VexxitTheme;
