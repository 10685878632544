import React from 'react';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import VexxitTheme from 'theme/App.theme';
import AppRoutes from 'layout/AppRoutes';
import Spinner from 'layout/Spinner';
import { ToastProvider } from 'react-toast-notifications';
import environment from 'environment';
import { userSnap } from 'utils/userSnap';
import { HelmetProvider } from 'react-helmet-async';

const App = () => {
  if (
    environment.currentEnv === 'dev' ||
    environment.currentEnv === 'uat' ||
    environment.currentEnv === 'stage'
  ) {
    userSnap('https://widget.usersnap.com/global/load/f7d2fb5d-6788-452f-805d-d00f2d0d4ea4');
  }

  return (
    <React.Suspense fallback={<Spinner />}>
      <ThemeProvider theme={VexxitTheme}>
        <ToastProvider placement="bottom-right" autoDismiss>
          <HelmetProvider>
            <div className="App">
              <Spinner />
              <AppRoutes />
            </div>
          </HelmetProvider>
        </ToastProvider>
      </ThemeProvider>
    </React.Suspense>
  );
};

export default App;
