import { FC, Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import Spinner from './Spinner';

const LoginPage = lazy(() => import('pages/Login/index' /* webpackChunkName: "login" */));
const ProfessionalDetailsPage = lazy(
  () => import('pages/Professional/Details/index' /* webpackChunkName: "pro-details" */),
);
const ResetPassword = lazy(
  () => import('pages/ResetPassword/index' /* webpackChunkName: "reset-password" */),
);
const ForgotPassword = lazy(
  () => import('pages/ForgotPassword/index' /* webpackChunkName: "forgot-password" */),
);
const ChangePassword = lazy(
  () => import('pages/ChangePassword/index' /* webpackChunkName: "change-password" */),
);
const ProfessionalPage = lazy(
  () => import('pages/Professional/index' /* webpackChunkName: "pro-list" */),
);
const DashboardPage = lazy(
  () => import('pages/Dashboard/index' /* webpackChunkName: "dashboard" */),
);
const ClientPage = lazy(() => import('pages/Client' /* webpackChunkName: "client-list" */));
const ClientDetailsPage = lazy(
  () => import('pages/Client/Details' /* webpackChunkName: "client-details" */),
);
const ChangeOrderRequestPage = lazy(
  () => import('pages/ChangeOrderRequests' /* webpackChunkName: "co-list" */),
);
const ChangeOrderRequestDetailsPage = lazy(
  () => import('pages/ChangeOrderRequests/Details' /* webpackChunkName: "co-details" */),
);
const ProductsPage = lazy(() => import('pages/Products' /* webpackChunkName: "product-list" */));

const DesignerProfessionalDetails = lazy(
  () =>
    import(
      'pages/Designer/Professional/ProfessionalDetails' /* webpackChunkName: "designer-pro-details" */
    ),
);
const DesignerChangePassword = lazy(
  () => import('pages/Designer/ChangePassword' /* webpackChunkName: "designer-change-password" */),
);
const DesignerResetPassword = lazy(
  () => import('pages/Designer/ResetPassword' /* webpackChunkName: "designer-reset-password" */),
);
const DesignerForgotPassword = lazy(
  () => import('pages/Designer/ForgotPassword' /* webpackChunkName: "designer-forgot-password" */),
);
const DesignerProfessional = lazy(
  () => import('pages/Designer/Professional' /* webpackChunkName: "designer-pro-list" */),
);
const DesignerClient = lazy(
  () => import('pages/Designer/Client' /* webpackChunkName: "designer-client-list" */),
);
const DesignerClientDetails = lazy(
  () => import('pages/Designer/Client/details' /* webpackChunkName: "designer-client-details" */),
);
const DesignerChangeOrder = lazy(
  () => import('pages/Designer/ChangeOrder' /* webpackChunkName: "designer-co-list" */),
);
const DesignerChangeOrderDetails = lazy(
  () => import('pages/Designer/ChangeOrder/details' /* webpackChunkName: "designer-co-details" */),
);
const DesignerProduct = lazy(
  () => import('pages/Designer/Product' /* webpackChunkName: "designer-product-list" */),
);

const AppRoutes: FC = () => (
  <BrowserRouter>
    <Routes>
      <Route path={ROUTES.LOGIN} element={<LoginPage />} />
      <Route path={ROUTES.DASHBOARD} element={<DashboardPage />} />
      <Route path={ROUTES.PROFESSIONAL} element={<ProfessionalPage />} />
      <Route path={ROUTES.PROFESSIONAL_DETAILS} element={<ProfessionalDetailsPage />} />
      <Route path={ROUTES.CHANGE_PASSWORD} element={<ChangePassword />} />
      <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />
      <Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} />
      <Route path={ROUTES.CLIENT} element={<ClientPage />} />
      <Route path={ROUTES.CLIENT_DETAILS} element={<ClientDetailsPage />} />
      <Route path={ROUTES.CHANGE_ORDER_REQUESTS} element={<ChangeOrderRequestPage />} />
      <Route
        path={ROUTES.CHANGE_ORDER_REQUESTS_DETAILS}
        element={<ChangeOrderRequestDetailsPage />}
      />
      <Route path={ROUTES.PRODUCTS} element={<ProductsPage />} />

      {/* designer pages */}
      <Route path={ROUTES.DesignerForgotPassword} element={<DesignerForgotPassword />} />
      <Route path={ROUTES.DesignerResetPassword} element={<DesignerResetPassword />} />
      <Route path={ROUTES.DesignerChangePassword} element={<DesignerChangePassword />} />
      <Route path={ROUTES.DesignerProfessional} element={<DesignerProfessional />} />
      <Route path={ROUTES.DesignerProfessionalDetails} element={<DesignerProfessionalDetails />} />
      <Route path={ROUTES.DesignerClient} element={<DesignerClient />} />
      <Route path={ROUTES.DesignerClientDetails} element={<DesignerClientDetails />} />
      <Route path={ROUTES.DesignerChangeOrder} element={<DesignerChangeOrder />} />
      <Route path={ROUTES.DesignerChangeOrderDetails} element={<DesignerChangeOrderDetails />} />
      <Route path={ROUTES.DesignerProduct} element={<DesignerProduct />} />

      {/* keep least always */}
      <Route path="*" element={<>Page not found</>} />
    </Routes>
  </BrowserRouter>
);

export default AppRoutes;
