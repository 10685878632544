import { HelperFunctions } from 'utils/helperFunctions';

const environment = {
  currentEnv: 'local', // 'dev', 'prod'
  version: '2.10',
  sentryDSN:
    'https://601a3c518d194cbea411619a34194875@o4504417144406016.ingest.sentry.io/4504417145126912',
  apiUrl: 'https://dev-vexxitapp2-api.azurewebsites.net/api/',
};

// Environment base runtime configuration *************************************
const { runConfig } = window as any;
HelperFunctions.mergeObj(environment, runConfig);

export default environment;
