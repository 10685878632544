import { useEffect } from 'react';

export const userSnap = (url: string) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.defer = true;
    script.src = url;
    // eslint-disable-next-line func-names
    script.onload = function (api: any) {
      api.init();
    };
    document.getElementsByTagName('head')[0].appendChild(script);

    return () => {
      document.getElementsByTagName('head')[0].removeChild(script);
    };
  }, [url]);
};
