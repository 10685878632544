/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-var-requires */
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  VexxitLogoPrimary: require('./vexxit-primary.svg').default,
  VexxitLogoWhite: require('./vexxit-white.svg').default,
  VisibilityHide: require('./password-hide.svg').default,
  VisibilityShow: require('./password-show.svg').default,
  UserAvatar1: require('./user-avatar1.jpg'),
  UserAvatar2: require('./user-avatar2.jpg'),
  UserAvatar3: require('./user-avatar3.jpg'),
  SelectChevronWhite: require('./select-chevron-white.svg').default,
  ProfileDark: require('./profile-dark.svg').default,
  LogoutDark: require('./logout-dark.svg').default,
  DashboardWhite: require('./dashboard-white.svg').default,
  ProfessionalWhite: require('./professional-white.svg').default,
  ColumnPrimary: require('./column-primary.svg').default,
  ChevronDownGrey: require('./chevron-down-grey.svg').default,
  AddWhite: require('./add-white.svg').default,
  MenuCollapsible: require('./menu-collapsible.svg').default,
  MoreDark: require('./more-dark.svg').default,
  Rating: require('./rating.svg').default,
  RatingFilled: require('./rating-filled.svg').default,
  SearchGrey: require('./search-grey.svg').default,
  ClosePrimary: require('./close-primary.svg').default,
  CheckWhite: require('./check-white.svg').default,
  ArrowLeftPrimary: require('./arrow-left-primary.svg').default,
  UserRectangularIMG: require('./user-rectangular.png'),
  AlertWarning: require('./alert-warning.svg').default,
  ChevronUpGrey: require('./chevron-up-grey.svg').default,
  CloseGrey: require('./close-grey.svg').default,
  DateGrey: require('./date-grey.svg').default,
  CheckCirclePrimaryLight: require('./check-circle-primary-light.svg').default,
  ForwardToInboxGrey: require('./forward-to-inbox-grey.svg').default,
  EditDanger: require('./edit-danger.svg').default,
  NoDataFound: require('./no-data-found-primary.svg').default,
  InfoGrey: require('./info-grey.svg').default,
  ChangePasswordDark: require('./change-password-dark.svg').default,
  EditNoteWhite: require('./edit-note-white.svg').default,
  EditNotePrimary: require('./edit-note-primary.svg').default,
  SendWhite: require('./send-white.svg').default,
  CopyLinkWhite: require('./copy-link-white.svg').default,
  EditGreyLG: require('./edit-grey-lg.svg').default,
  NoMessageStyledPrimary: require('./no-message-styled-primary.svg').default,
  ChevronDownWhite: require('./chevron-down-white.svg').default,
  CloseWhite: require('./close-white.svg').default,
  EmailStyledPrimary: require('./email-styled-primary.svg').default,
  InvalidLinkPrimary: require('./invalid-link-primary.svg').default,
  PasswordChangedStyled: require('./password-changed-styled.svg').default,
  MoreVerticalPrimary: require('./more-vertical-primary.svg').default,
  ClientWhite: require('./client-white.svg').default,
  UserDefaultWhite: require('./user-default-white.svg').default,
  ProjectProImg: require('./project-pro.jpg'),
  PDFStyledPrimary: require('./pdf-styled-primary.svg').default,
  UploadStyledPrimary: require('./upload-styled-primary.svg').default,
  CheckMarkBlack: require('./checkmark-black.svg').default,
  ChevronRightWhite: require('./chevron-right-white.svg').default,
  DeletePrimary: require('./delete-primary.svg').default,
  EnableUserPrimary: require('./enable-user-primary.svg').default,
  DisableUserPrimary: require('./disable-user-primary.svg').default,
  DocumentStyledPrimary: require('./document-styled-primary.svg').default,
  DollarSignBlack: require('./dollar-sign-black.svg').default,
  PDFWhite: require('./pdf-white.svg').default,
  DeleteGrey: require('./delete-grey.svg').default,
  EnableUserGrey: require('./enable-user-grey.svg').default,
  DisableUserGrey: require('./disable-user-grey.svg').default,
  HistoryGrey: require('./history-grey.svg').default,
  ProductsWhite: require('./products-white.svg').default,
  NoUserFound: require('./no-user-found.svg').default,
  ProductsGrey: require('./products-grey.svg').default,
};
